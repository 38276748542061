<template>
  <section class="projects fade">
    <div class="inline-grid-container container-padding">
      <LandingTitleBlock
        :title="$t('projects')" :filters="filterTypes" isFilter
        @changeActiveTab="changeActiveTab"
      />
    </div>

    <Loader v-if="loading" />
    <div class="project-list container-padding" >
      <ProjectItem v-for="el in filteredProjects" :key="el.id"
                   :project="el" @openProject="openProject"
      />
    </div>

    <button
      type="button"
      class="yellow-btn"
      v-if="!!projects.next"
      @click="getMoreProjects"
    >
      {{$t('showMore')}}
    </button>
  </section>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import LandingTitleBlock from '../../components/LandingTitleBlock.vue';
import ProjectItem from '../../components/ProjectItem.vue';
import Loader from '../../components/UI/Loader.vue';

export default {
  name: 'Projects',

  data() {
    return {
      loading: true,
      activeTab: null,
      page: 1
    }
  },

  computed: {
    ...mapState('projects', ['projects', 'projectTypes']),
    filteredProjects() {
      if (this.activeTab === 0) {
        return this.projects.results
      }
      return this.projects.results.filter(el => +el.project_type === +this.activeTab)
    },
    filterTypes() {
      return [{ id: 0, type: this.$t('allProjects') }].concat(this.projectTypes)
    },
  },

  methods: {
    ...mapActions('projects', ['getProjects', 'getProjectTypes']),
    ...mapMutations('projects', ['resetProjects']),
    async changeActiveTab(el) {
      this.loading = true
      this.resetProjects()
      this.page = 1
      this.activeTab = el
      await this.getProjects({page: this.page, pageSize: 8, typeId: this.activeTab})
      this.loading = false
    },

    openProject(id) {
      this.$router.push(`/projects/project/${id}`)
    },

    async getMoreProjects() {
      this.page++
      await this.getProjects({page: this.page, pageSize: 8, typeId: this.activeTab})
    }

  },
  async mounted() {
    console.log(this.filterTypes)
    this.resetProjects()
    await this.getProjects({page: this.page, pageSize: 8})
    await this.getProjectTypes()
    this.activeTab = this.filterTypes[0].id
    this.loading = false
    console.log(this.filterTypes)
  },

  components: { LandingTitleBlock, ProjectItem, Loader},
};
</script>

<style lang="scss" scoped>
.inline-grid-container{
  width: 100%;
}
.project-list {
  margin-top: 48px;
  display: grid;
  grid-template-columns: repeat(3, minmax(311px, 460px));
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-auto-rows: 310px;
  grid-auto-flow: row dense;
}

@media screen and (max-width: 1210px) {
  .project-list {
    grid-auto-rows: 231px;
  }
}

@media (min-width: 965px) and (max-width: 1100px) {
  .project-list {
    grid-template-columns: repeat(3, minmax(0, 460px));
  }
}

@media (min-width: 521px) and (max-width: 964px) {
  .project-list {
    grid-template-columns: repeat(2, minmax(0, 460px));
  }
}

@media (max-width: 520px ) {
  .project-list {
    grid-template-columns: repeat(1, minmax(0, 460px));
  }
}

@media (max-width: 1100px) {
  .project-list {
    margin-top: 0;
  }
}
</style>
