<template>
  <div class="link" @click="goTo">
    <p :style="{fontSize: fontSize}" :class="{color: color}">{{title}}</p>
    <span :class="{color: color}"></span>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'CustomLink',

  methods: {
    ...mapMutations(['setScrollY']),
    goTo() {
      if (this.url) {
        window.open(this.url, '_blank');
      } else if (this.link) {
        this.setScrollY(window.scrollY)
        this.$router.push(this.link)
      }
    },
  },

  props: {
    title: {
      type: String,
    },
    fontSize: {
      type: String,
      default: '14px',
    },
    url: {
      type: String,
      default: null,
    },
    link: {
      type: String,
      default: null,
    },
    color: {
      type: Boolean,
    },
  },
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";
.link {
  display: inline-block;
  cursor: pointer;
  p {
    color: $green;
  }

  p.color {
    color: white;
  }

  span {
    height: 1px;
    width: 100%;
    background-color: $text-blue;
    opacity: 0.15;
    display: block;
    transition: .3s;
  }

  span.color {
    background-color: white;
  }

  &:hover {
    p.color {
      color: white;
    }
    span {
      background-color: $yellow;
      transition: .3s;
      opacity: 1;
    }
    span.color {
      background-color: white;
      opacity: 1;
      transition: .3s;
    }
  }
}
</style>
