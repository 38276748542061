<template>
  <section class="wrapper project-head-content">
    <div
      class="comeback"
      @click="comeback"
    >
      <v-icon>mdi-chevron-left</v-icon>
      <p>{{ $t('comeback') }}</p>
    </div>
    <div
      class="project-block"
      v-if="isOneProject"
    >
      <div class="d-flex">
        <div
          class="date"
          v-if="data"
        >
          {{ new Date(data).toLocaleString(currentLang, {day: 'numeric', month: 'numeric', year: 'numeric'}) }}
        </div>
        <div
          class="tags"
          v-if="tags"
        >
          <div
            v-for="el in tags"
            :key="el"
          >
            <p
              @click="selectTag(el)"
              style="cursor: pointer"
            >
              {{ el }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="share-block"
        @click="isShareModal=true"
      >
        <span>{{ $t('share') }}</span>
        <div class="share"></div>
      </div>
      <v-dialog
        class="dialog"
        v-model="isShareModal"
        max-width="396px"
        @click:outside="isUserModal = false"
      >
        <v-card class="modal">
          <v-card-actions class="close-btn">
            <button @click="isShareModal = false">
              <v-icon class="close-icon">mdi-close</v-icon>
            </button>
          </v-card-actions>
          <div class="iframe-container">
            <p>{{ $t('share') }}</p>
            <!-- <div class="social-link">
              <ShareNetwork
                v-for="network in networks"
                :key="network.name"
                :network="network.name"
                :url="url"
                :title="title"
              >
                <span class="fa-stack fa-sm">
                  <i class="fa fa-circle fa-stack-2x "></i>
                  <i :class="network.icon"></i>
                </span>
              </ShareNetwork>
            </div> -->

            <div class="social-link">
              <div
                v-for="network in networks"
                :key="network.name"
              >
                <span
                  class="fa-stack fa-sm"
                  style="cursor: pointer"
                  @click="share(network)"
                >
                  <i class="fa fa-circle fa-stack-2x"></i>
                  <i :class="network.icon"></i>
                </span>
              </div>
            </div>

            <div
              class="copy-link"
              @click="copyLink"
            >
              <i class="fas fa-link"></i>
              <span>{{$t('copyLink')}}</span>
            </div>
          </div>
          <transition name="fade">
            <div v-if="snackBar" class="notification">
              {{$t('copied')}}
            </div>
          </transition>
        </v-card>
      </v-dialog>
    </div>

    <div class="title-block">
      <div class="title-block-top">
        <h3 :class="{'project-title': isOneProject || isPersonal}">{{ title }}
          <span
            class="tagNews"
            v-if="tag"
          >
            {{tag}}
            <button @click="clearTag()">
              <v-icon class="close-icon">mdi-close</v-icon>
            </button>
          </span>
        </h3>
      </div>
      <div
        class="filters-block filters-desktop"
        v-if="isFilter"
      >
        <ul class="filters">
          <li
            v-for="el in filters"
            :key="el.id"
            @click="changeActiveTab(el)"
            class="filters-item"
            :class="{active: activeTab === el.id}"
          >{{ el.type }}
          </li>
        </ul>
      </div>

      <div
        class="filters-mobile dynamic-content"
        v-if="isFilter"
      >
        <swiper
          :options="swiperOptions"
          class="swiper"
          :delete-instance-on-destroy="true"
          :cleanup-styles-on-destroy="true"
        >
          <swiper-slide
            v-for="el in filters"
            :key="el.id"
            class="slider__slide"
          >
            <span
              :key="el.id"
              @click="changeActiveTab(el)"
              class="filters-item"
              :class="{active: activeTab === el.id}"
            >{{
                el.type
              }}</span>
          </swiper-slide>
          <div
            class="swiper-scrollbar"
            slot="pagination"
          ></div>
        </swiper>
      </div>
    </div>
    <div
      class="channel"
      v-if="isChannel"
    >
      <p>YouTube {{ $t('channel') }} </p>
      <CustomLink
        :title="$t('channelSvoe')"
        url="https://www.youtube.com/channel/UCny_HyF7Y8qLPvrIE0zEJGQ"
      />
    </div>
  </section>
</template>

<script>
import CustomLink from './UI/CustomLink.vue';
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: 'LandingTitleBlock',

  data () {
    return {
      activeTab: 0,
      isShareModal: false,
      networks: [
        { name: 'telegram', icon: 'fab fa-telegram-plane fa-stack-1x fa-inverse' },
        { name: 'facebook', icon: 'fab fa-facebook-f fa-stack-1x fa-inverse' },
        { name: 'vk', icon: 'fab fa-vk fa-stack-1x fa-inverse' },
        { name: 'odnoklassniki', icon: 'fab fa-odnoklassniki fa-stack-1x fa-inverse' },
      ],
      url: '',
      snackBar: false,
    }
  },
  computed: {
    ...mapState(['swiperOptions']),
    ...mapState(['lang']),
    currentLang () {
      return this.lang === 'ru' ? 'ru' : 'en'
    },
    swiper () {
      return this.$refs.mySwiper.$swiper
    },
  },

  methods: {
    ...mapMutations('news', ['setTag', 'resetNews']),
    ...mapActions('news', ['getNews']),

    async clearTag () {
      this.setTag('')
      this.resetNews()
      await this.getNews({})
    },

    selectTag (el) {
      this.setTag(el)
      this.$router.push(`/news`)
    },

    share (nerwork) {
      let mainURL = this.url;
      let mainURLIMG = window.location.origin;

      if (mainURL.indexOf('localhost') !== -1) mainURL = 'https://svoe-dev.bsl.dev/projects/project/6';
      if (mainURLIMG.indexOf('localhost') !== -1) mainURLIMG = 'https://svoe-dev.bsl.dev';

      if (nerwork.name === 'vk') {
        let url = 'http://vkontakte.ru/share.php?'
        let link = `url=${encodeURIComponent(mainURL)}`
        let descr = `&description=`
        let title = `&title=${encodeURIComponent(this.title)}`
        let img = '&image=' + encodeURIComponent(`${mainURLIMG}/api/v1/images/${this.pic_url}`)
        let endUrl = `${url}${link}${title}${descr}${img}&noparse=true`
        window.open(endUrl, '', 'toolbar=0,status=0,width=626,height=436')
      } else if (nerwork.name === 'facebook') {
        window.open(
          `http://facebook.com/sharer/sharer.php?u=${encodeURIComponent(mainURL)}
          &p[summary]=${encodeURIComponent(this.title)}`,
          'sharer',
          'left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0'
        )
      } else if (nerwork.name === 'odnoklassniki') {
        window.open(
          `https://connect.ok.ru/dk?st.cmd=WidgetSharePreview&st.shareUrl=${mainURL}&st.comments=комментарий`,
          'sharer',
          'status=0,toolbar=0,width=650,height=500'
        );
      } else if (nerwork.name === 'telegram') {
        window.open(`https://t.me/share/url?url=${mainURL}&text=${this.title}`,
          'sharer',
          'status=0,toolbar=0,width=650,height=500');
      }
    },

    changeActiveTab (el) {
      this.activeTab = el.id
      this.$emit('changeActiveTab', el.id)
    },
    comeback () {
      this.$router.back()
    },
    copyLink () {
      const cb = navigator.clipboard;
      cb.writeText(this.url)
      this.snackBar = true
      setTimeout(() => {this.snackBar=false}, 1500)
    }
  },

  mounted () {
    this.url = location.href
  },

  components: { CustomLink },

  props: {
    title: {
      type: String,
    },
    pic_url: {
      type: String,
      default: 'false',
    },
    tags: {
      type: Array
    },
    tag: {
      type: String,
      default: '',
    },
    data: {
      type: String
    },
    isFilter: {
      type: Boolean,
      default: false,
    },
    isChannel: {
      type: Boolean,
      default: false,
    },
    isComeback: {
      type: Boolean,
      default: false,
    },
    isOneProject: {
      type: Boolean,
      default: false,
    },
    isPersonal: {
      type: Boolean,
      default: false,
    },
    lineHeight: {
      type: String,
    },
    filters: {},
  },
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";
.social-link {
  display: flex;
  margin-top: 32px;
  margin-bottom: 26px;
}
@media (hover: hover) and (pointer: fine) {
  .copy-link {
    &:hover {
      i,
      span {
        color: $green;
      }
    }
  }
  .social-link {
    span {
      &:hover {
        i.fa-stack-2x {
          color: $green !important;
        }
      }
    }
  }
}
.copy-link {
  cursor: pointer;
  i {
    margin-right: 7px;
  }
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 56px;
}

.close-btn {
  width: 100%;
  justify-content: flex-end;
  padding: 14px 24px !important;
}

.iframe-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-weight: 600;
    font-size: 26px;
    line-height: 33px;
  }
}

.project-block {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 14px;
  margin-bottom: 7px;
}

.share-block {
  display: flex;
  align-items: baseline;
  cursor: pointer;
}

.share {
  width: 18px;
  height: 15px;
  margin-left: 7px;
  background-image: url("../assets/img/vector.svg");
}

.comeback {
  margin-bottom: 16px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  p {
    margin: 0 0 0 10px;
    transition: 0.3s;
  }

  &:hover {
    p {
      color: $green;
      transition: 0.3s;
    }

    .v-icon {
      transition: 0.3s;
      color: $green;
    }
  }
}

.title-block {
  display: flex;
  align-items: center;
  //flex-direction: column;
  .title-block-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h3 {
    font-weight: 600;
    font-size: 44px;
    line-height: 54px;
    margin-right: 25px;
    min-width: 210px;
  }
}

.filters-block {
  overflow-x: auto;
  width: 100%;
}

.filters-mobile {
  display: none;
  width: 100%;
  margin-bottom: 0;
  margin-top: 0;
}

.filters {
  display: flex;
  align-items: center;
  padding: 0;
  // min-width: 800px;
  &-item {
    white-space: nowrap;
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 15px;
    padding: 5px 12px;
    border: 1px solid $light-gray;
    box-sizing: border-box;
    cursor: pointer;
    transition: 0.3s;

    &:hover,
    &.active {
      background-color: $green;
      color: white;
      transition: 0.3s;
    }

    &:first-child {
      border-radius: 5px 0 0 5px;
    }

    &:last-child {
      border-radius: 0 5px 5px 0;
    }
  }
}

.channel {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-top: 24px;
  margin-bottom: 32px;

  p {
    margin-right: 5px;
  }
}

.project-title {
  line-height: 50px !important;
  font-size: 36px !important;
}

.date {
  font-weight: 400;
  font-size: 16px;
  line-height: 19.5px;
  color: #a0a0a0;
}

.tags {
  margin-left: 43px;
  display: flex;
  flex-wrap: wrap;

  p {
    margin-right: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.5px;
  }
}

@media screen and (max-width: 1100px) {
  .share-block {
    span {
      display: none;
    }
  }
  .project-title {
    line-height: 33px !important;
    font-size: 22px !important;
  }
  .title-block {
    flex-wrap: wrap;

    h3 {
      font-size: 32px;
      line-height: 39px;
    }
  }
  .filters-desktop {
    display: none;
  }
  .date {
    font-size: 14px;
  }
  .tags {
    p {
      font-size: 14px;
    }
  }
  .channel {
    margin-top: -16px;
  }
  .filters-mobile {
    display: block;
    margin-bottom: 32px;
    margin-top: 24px;
  }

  .slider__slide {
    flex-shrink: 1;
    max-width: fit-content;
  }

  .swiper {
    div {
      &:first-child > span {
        border-radius: 5px 0 0 5px !important;
      }

      &:last-child > span {
        border-radius: 0 5px 5px 0 !important;
      }

      &:not(:first-child):not(:last-child) > span {
        border-radius: 0 !important;
      }
    }
  }

  .filters-item {
    width: fit-content;
  }

  .filters {
    display: flex;
    align-items: center;
    padding: 0;

    &-item {
      font-size: 15px;
      padding: 12px;
      border: 1px solid #c4c4c4;
      box-sizing: border-box;

      &:hover,
      &.active {
        border: 1px solid transparent;
      }
    }
  }

  .swiper-wrapper {
    &:first-child {
      border-radius: 5px 5px 5px 5px;
    }

    &:last-child {
      border-radius: 5px 0 5px 0;
    }
  }
}

.project-head-content {
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 13;
}

.tagNews {
  font-size: 20px;
  margin: 10px;
  button {
    position: relative;
    bottom: 5px;
  }
}

.notification{
  background: rgba(34, 34, 34, 0.6);
  position: absolute;
  bottom: 10px;
  height: 40px;
  width: 240px;
  color: white;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius !important;
}


@media (max-width: 400px) {
  .channel {
    flex-direction: column;
    align-items: flex-start;

    div {
      width: fit-content;
    }
  }


}
</style>
