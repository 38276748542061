<template>
  <div class="project-list__item project-card fade" @click="$emit('openProject', project.id)"
       :class="[project.big_block ? 'large': 'small', {'main-pr': mainProjects, margin: margin}]">
    <v-img :src="`${ApiUrl}v1/images${project.pic_url}`"
           class="item__img overlay-image" :alt="project.title"
           :class="[project.big_block ? 'large__img': 'small__img', {'main-pr': mainProjects}]"
    />
    <p>{{project.title}}</p>
  </div>
</template>

<script>
import {ApiUrl} from "@/global";

export default {
  name: 'ProjectItem',
  data() {
    return {
      ApiUrl
    }
  },
  props: {
    project: {
      type: Object,
    },
    margin: {
      type: Boolean,
      default: false,
    },
    mainProjects: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.project-list__item {
  .item__img {
    min-height: 206px;
  }
}
  .project-card {
    border-radius: 20px;
  }

  .small {
    margin-bottom: 0 !important;
  }

  .project-list__item .item__img.main-pr.large__img {
    max-height: 536px;
    height: 536px;
  }

  .v-responsive__content:hover {
    color: red;
    background-color: red;
  }
  .large__img {
    height: 536px;
    max-height: 536px;
  }

@media screen and (max-width: 1210px) {
  .project-list__item .item__img.main-pr.large__img {
    max-height: 408px;
    height: 408px ;
    min-height: auto;
  }
  .large__img {
    height: 408px;
    max-height: 408px;
  }
  .project-list__item {
    .item__img {
      min-height: 157px;
    }
  }
}
@media screen and (max-width: 1100px) {
  .large {
    min-height: 230px;
    grid-column: span 1;
    grid-row: span 1;
  }
  .large__img {
    height: 157px;
    max-height: 157px;
  }
  .project-list__item {
    max-width: 460px;
  }
}
</style>
